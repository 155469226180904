// main.js
import Vue from 'vue';
import App from './App.vue';
import { getAuth0 } from './auth';

Vue.config.productionTip = false;

// Function to initialize Auth0 and handle redirect callback
const initializeAuth = async () => {
  const auth0 = await getAuth0();
  console.log('Auth0 Client Initialized:', auth0);

  // Check if the URL contains authentication parameters
  if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
    try {
      await auth0.handleRedirectCallback();
      // Remove authentication parameters from the URL
      window.history.replaceState({}, document.title, window.location.pathname);
      console.log('Auth0 Redirect Callback Handled');
    } catch (err) {
      console.error('Error handling redirect callback:', err);
    }
  }

  // Mount the Vue instance after handling the redirect
  new Vue({
    render: h => h(App),
    data: {
      auth0: auth0,
    },
    provide: {
      auth0: auth0,
    },
  }).$mount('#app');
};

// Initialize authentication and mount the app
initializeAuth();

