<!-- App.vue -->
<template>
  <div id="app">
    <nav class="navbar">
      <!-- Display user information if authenticated -->
      <button v-if="isAuthenticated" @click="copyJwtToClipboard">Copy JWT</button>

      <div v-if="isAuthenticated" class="user-info">
        <img :src="user.picture" alt="User Avatar" class="avatar" />
        <span>{{ user.name }}</span>
        <button @click="logout">Log Out</button>
      </div>
      <!-- Show login button if not authenticated -->
      <div v-else>
        <button @click="login">Log In</button>
      </div>
    </nav>

    <!-- Loading State -->
    <div v-if="isLoading" class="loading">
      <p>Loading...</p>
    </div>

    <!-- Error State -->
    <div v-if="error" class="error">
      <p>{{ error }}</p>
    </div>

    <!-- Protected Content -->
    <div v-if="isAuthenticated && !isLoading" class="grid-container">
      <!-- Your existing grids go here -->
      <ag-grid-vue
        class="ag-theme-quartz"
        :columnDefs="futuresColumnDefs"
        :rowData="futuresRowData"
        :gridOptions="futuresGridOptions"
        :domLayout="'autoHeight'"
        @grid-ready="onFuturesGridReady"
      ></ag-grid-vue>

      <ag-grid-vue
        class="ag-theme-quartz"
        :columnDefs="rawColumnDefs"
        :rowData="rawRowData"
        :gridOptions="rawGridOptions"
        :domLayout="'autoHeight'"
        @grid-ready="onRawGridReady"
      ></ag-grid-vue>

      <ag-grid-vue
        class="ag-theme-quartz-dark"
        :columnDefs="deltaVolsColumnDefs"
        :rowData="deltaVolsRowData"
        :gridOptions="deltaVolsGridOptions"
        :domLayout="'autoHeight'"
        @grid-ready="onDeltaVolsGridReady"
      ></ag-grid-vue>

      <ag-grid-vue
        class="ag-theme-quartz-dark"
        :columnDefs="strikeVolsColumnDefs"
        :rowData="strikeVolsRowData"
        :gridOptions="strikeVolsGridOptions"
        :domLayout="'autoHeight'"
        @grid-ready="onStrikeVolsGridReady"
      ></ag-grid-vue>
    </div>

    <!-- Message for Unauthenticated Users -->
    <div v-else-if="!isLoading && !isAuthenticated" class="unauthenticated-message">
      <h2>Welcome! Please log in to access the application.</h2>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";

export default {
  name: "App",
  components: {
    AgGridVue,
  },
  inject: ['auth0'], // Inject Auth0 instance
  data() {
    return {
      isAuthenticated: false,
      user: null,
      isLoading: true,
      error: null,
      // Your existing data properties
      deltaVolsColumnDefs: [],
      strikeVolsColumnDefs: [],
      futuresColumnDefs: [
        { field: 'ticker', headerName: 'Ticker', filter: true },
        { field: 'bid', headerName: 'Bid', filter: false, enableCellChangeFlash: true, valueFormatter: this.toTwoDecimalPlaces},
        { field: 'ask', headerName: 'Ask', filter: false, enableCellChangeFlash: true, valueFormatter: this.toTwoDecimalPlaces},
        { field: 'filtered_mid', headerName: 'Filtered Mid', filter: false, cellRenderer: 'agAnimateShowChangeCellRenderer', valueFormatter: this.toTwoDecimalPlaces },
      ],
      rawColumnDefs: [
        { field: 'maturity', headerName: 'Maturity', filter: true, valueFormatter: this.formatMaturity, rowGroup: true },
        { field: 'strike', headerName: 'Strike', filter: true },
        {
          field: 'bid',
          headerName: 'Bid',
          filter: true,
          cellRenderer: 'agAnimateShowChangeCellRenderer',
          valueFormatter: this.toThreeDecimalPlaces
        },
        {
          field: 'ask',
          headerName: 'Ask',
          filter: true,
          cellRenderer: 'agAnimateShowChangeCellRenderer',
          valueFormatter: this.toThreeDecimalPlaces
        },
        { field: 'log_moneyness', headerName: 'Log Moneyness', filter: true },
        { field: 'vega', headerName: 'Vega', filter: true },
        { field: 'bid_source', headerName: 'Bid Source', filter: true },
        { field: 'ask_source', headerName: 'Ask Source', filter: true }
      ],
      rawRowData: [],
      futuresRowData: [],
      strikeVolsRowData: [],
      deltaVolsRowData: [],
      rawGridOptions: {
        animateRows: true,
        deltaRowDataMode: true,
        autoGroupColumnDef: {
          headerName: 'Maturity',
          field: 'maturity',
          minWidth: 200,
          cellRendererParams: {
            suppressCount: true,
          },
        },
        getRowId: params => params.data.rowId,
      },
      deltaVolsGridOptions: {
        suppressFieldDotNotation: true,
        getRowId: params => params.data.maturity,
      },
      strikeVolsGridOptions: {
        suppressFieldDotNotation: true,
      },
      futuresGridOptions: {
        animateRows: true,
        deltaRowDataMode: true,
        getRowId: params => params.data.rowId,
      },
      rawGridApi: null,
      futuresGridApi: null,
      socket: null,
    };
  },
  mounted() {
    this.checkAuthentication();
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
  },
  methods: {
  async copyJwtToClipboard() {
    try {
      //const token = await this.auth0.getTokenSilently();
      await navigator.clipboard.writeText(this.token);
      alert('JWT copied to clipboard!');
    } catch (error) {
      console.error('Error copying JWT:', error);
    }
  },

    async checkAuthentication() {
      try {
        this.isAuthenticated = await this.auth0.isAuthenticated();
        if (this.isAuthenticated) {
          this.user = await this.auth0.getUser();
          console.log('*********** hello ***********');
          console.log('User:', this.user);
          this.token = await this.auth0.getTokenSilently();
          console.log("JWT Token:", this.token);

        }
        this.isLoading = false;
      } catch (err) {
        this.error = err.message;
        this.isLoading = false;
      }
    },
    login() {
      this.auth0.loginWithRedirect();
    },
    logout() {
      this.auth0.logout({
        returnTo: window.location.origin,
      });
    },
    // Existing methods...

    initializeWebSocket() {
      this.socket = new WebSocket('ws://127.0.0.1:8000/ws/market-data');

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      this.socket.onmessage = event => {
        const data = JSON.parse(event.data);
        if (data.paint){
            this.processWebSocketData(data);
        } else{
            this.processMarketUpdateData(data);
        }
      };

      this.socket.onclose = () => {
        console.log('WebSocket connection closed');
      };

      this.socket.onerror = error => {
        console.error('WebSocket error:', error);
      };
    },
    processMarketUpdateData(data) {
      // Existing processing logic...
      if (data.future){
          const key = Object.keys(data.future)[0];
          const newData = data.future[key];
          const rowNode = this.futuresGridApi.getRowNode(key);
          if (rowNode){
              rowNode.setDataValue(Object.keys(newData)[0], newData[Object.keys(newData)[0]]);
          }
      } 
      else if (data.smile){
          const key = Object.keys(data.smile)[0];
          const newData = data.smile[key];
          const rowNode = this.rawGridApi.getRowNode(key);
          if (rowNode){
              rowNode.setDataValue(Object.keys(newData)[0], newData[Object.keys(newData)[0]]);
          }
      }
      else if (data.smile_svi_delta_vol){
          console.log(data.smile_svi_delta_vol);
          const key = Object.keys(data.smile_svi_delta_vol)[0];
          const newData = data.smile_svi_delta_vol[key];
          const rowNode = this.deltaVolsGridApi.getRowNode(key);
          console.log(key, rowNode);
          console.log(data.smile_svi_delta_vol[key]);
          if (rowNode){
              rowNode.setDataValue(
                  Object.keys(newData)[0], 
                  newData[Object.keys(newData)[0]]
              );
          }
      }
      else{
          console.warn('Invalid data detected:', data);
      }
    },

    reorderColumnDefs(columnDefs) {
      const maturityColumn = columnDefs.filter((col) => col.field === "maturity");
      const futureColumn = columnDefs.filter((col) => col.field === "future");
      const pColumns = columnDefs
        .filter((col) => col.field.includes("p"))
        .sort((a, b) => parseFloat(a.field) - parseFloat(b.field));
      const atmfColumn = columnDefs.filter((col) => col.field === "ATMF");
      const cColumns = columnDefs
        .filter((col) => col.field.includes("c"))
        .sort((a, b) => parseFloat(a.field) - parseFloat(b.field));

      return [...maturityColumn, ...futureColumn, ...pColumns, ...atmfColumn, ...cColumns];
    },

    transformData(data) {
      return Object.keys(data).map(maturity => {
        return {
          maturity: maturity,
          ...data[maturity]
        };
      });
    },

    updateSVIGrids(gridObject, data) {
      console.log(data);
      const key = Object.keys(data)[0];
      const col_names = Object.keys(data[key]);
      const columns = [];
      columns.push(
        { 
          field: 'maturity', 
          headerName: 'Maturity', 
          filter: true, 
          valueFormatter: this.formatMaturity 
        },
        { 
          field: 'future', 
          headerName: 'Future', 
          cellStyle: { color: '#fb8b1e' },
          filter: true, 
        }
      );
      for (let i = 0; i < col_names.length; i++) {
        columns.push({
          field: col_names[i],
          headerName: col_names[i],
          valueFormatter: this.toThreeDecimalPlaces,
          cellRenderer: 'agAnimateShowChangeCellRenderer',
          filter: true
        });
      }
      gridObject.setColumnDefs(this.reorderColumnDefs(columns));
      if (gridObject){ 
        gridObject.setRowData(this.transformData(data));
        gridObject.sizeColumnsToFit(); // Initial sizing
        gridObject.autoSizeAllColumns(); // Auto-size columns based on data
      }
    },

    processWebSocketData(data) {
      if (data.paint) {
        if (data.paint.svi_grid){
          const gridType = 'delta';
          if (gridType === 'delta'){
            console.log('Updating Delta Vols Grid');
            this.updateSVIGrids(this.deltaVolsGridApi, data.paint.svi_grid['delta']);
          }
        }
        if (data.paint.options) {
          const newRawData = [];
          for (const [maturity, strikes] of Object.entries(data.paint.options)) {
            for (const [strike, details] of Object.entries(strikes)) {
              if (maturity && strike) {
                const rowId = `${maturity}_${strike}`;
                newRawData.push({
                  rowId,
                  maturity,
                  strike: parseFloat(strike),
                  bid: details.bid,
                  ask: details.ask,
                  log_moneyness: details.log_moneyness,
                  vega: details.vega,
                  bid_source: details.bid_source,
                  ask_source: details.ask_source
                });
              } else {
                console.warn('Invalid data detected:', { maturity, strike });
              }
            }
          }
          this.rawRowData = newRawData;
          if (this.rawGridApi) {
            this.rawGridApi.setRowData(newRawData);
          }
        }
        if (data.paint.futures) {
          const newFuturesData = [];
          for (const future of data.paint.futures) {
            if (future.ticker) {
              const rowId = future.ticker;
              newFuturesData.push({
                rowId,
                ticker: future.ticker,
                expiry: future.expiry,
                bid: future.bid,
                ask: future.ask,
                mid: future.mid,
                filtered_mid: future.filtered_mid,
                open_interest: future.open_interest,
                volume: future.volume
              });
              if (this.futuresGridApi) {
                this.futuresGridApi.setRowData(newFuturesData);
              } else {
                console.warn('Invalid futures data detected:', future);
              }
            }
          }
        }
      } else {
        console.log('Data update received without paint information');
      }
    },

    onDeltaVolsGridReady(params) {
      console.log('Delta Vols Grid is ready!');
      this.deltaVolsGridApi = params.api;
    },

    onStrikeVolsGridReady(params) {
      console.log('Strike Vols Grid is ready!');
      this.strikeVolsGridApi = params.api;
    },
    onRawGridReady(params) {
      console.log('Raw Grid is ready!');
      this.rawGridApi = params.api;
    },
    onFuturesGridReady(params) {
      console.log('Futures Grid is ready!');
      this.futuresGridApi = params.api;
    },
    formatMaturity(params) {
      const date = new Date(parseInt(params.value, 10));
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear().toString().substr(-2);
      return `${day}-${month}-${year}`;
    },
    toTwoDecimalPlaces(params) {
      return params.value ? params.value.toFixed(2) : params.value;
    },
    toThreeDecimalPlaces(params) {
      return params.value ? params.value.toFixed(3) : params.value;
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.initializeWebSocket();
    }
  },
};
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-quartz.css";

.navbar {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #f8f8f8;
}

.user-info {
  display: flex;
  align-items: center;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.grid-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Optional: adds space between the grids */
  padding: 1rem;
}

.ag-theme-quartz {
  flex: 1 1 auto;
}

.unauthenticated-message {
  text-align: center;
  margin-top: 2rem;
}

.loading, .error {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  color: #ff0000;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>

