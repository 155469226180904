// src/auth.js
import { createAuth0Client } from '@auth0/auth0-spa-js';

let instance;

export const getAuth0 = () => {
  if (!instance) {
    // Debugging: Log environment variables to verify they're loaded correctly
    console.log('Auth0 Domain:', process.env.VUE_APP_AUTH0_DOMAIN);
    console.log('Auth0 Client ID:', process.env.VUE_APP_AUTH0_CLIENT_ID);
          const redirectUri = window.location.origin;

      console.log('Configured redirect_uri:', redirectUri);
    
    instance = createAuth0Client({
      domain: process.env.VUE_APP_AUTH0_DOMAIN, // Uses environment variable
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID, // Uses environment variable
      authorizationParams: {
    redirect_uri: process.env.VUE_APP_REDIRECT_URI,
                cacheLocation: 'localstorage', // Ensures tokens are stored in localStorage
      audience: 'https://quickstarts/api',
      useRefreshTokens: true, // Optional: improves token handling

  }
    });
  }
  return instance;
};

